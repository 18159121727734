import { cn } from '@/lib/utils'
import * as SelectRadix from '@radix-ui/react-select'
import { forwardRef, ReactNode, useRef, useState } from 'react'
import { ISelect } from './ISelect'
import { Icon } from '../Icon'
import { Paragraph } from '@/components/sharedComponents/Paragraph'

export const SelectItem = forwardRef<
	HTMLDivElement,
	{
		children: ReactNode
		value?: string
	} & Omit<SelectRadix.SelectItemProps, 'value'>
>(
	(
		{
			children,
			value = typeof children === 'string' ? children : 'valor',
			className,
			...props
		},
		ref,
	) => {
		return (
			<SelectRadix.Item
				ref={ref}
				className={cn(
					`
					cursor-pointer m-0 py-1 px-2 w-full box-border leading-4 text-xs font-normal rounded-md h-10 flex items-center
					data-[disabled='']:text-grey-4
					data-[highlighted='']:bg-grey-6 data-[highlighted='']:outline-none data-[highlighted='']:outline-transparent text-grey-2
				`,
					className,
				)}
				value={value}
				{...props}
			>
				<SelectRadix.ItemText>{children}</SelectRadix.ItemText>
			</SelectRadix.Item>
		)
	},
)

export function SelectLabel({
	children,
	value,
}: {
	children: string
	value: string | null
}) {
	return (
		<div
			className="absolute block overflow-hidden whitespace-nowrap overflow-ellipsis max-w-[calc(100%-24px)] bg-white px-1 z-1 transition-transform transition-max-w duration-200 ease-in transform origin-top-left scale-75 md:scale-90 text-grey-2 leading-normal rounded-md text-sm pointer-events-none translate-x-[14px] translate-y-[15px] data-[aria-hidden=true]:translate-y-[-12px] data-[aria-hidden=true]:translate-x-[12px] data-[aria-hidden=true]:scale[0.875] data-[has-value=true]:translate-y-[-12px] data-[has-value=true]:translate-x-[10px] data-[has-value=true]:scale[0.875] data-[has-value=false]:bg-transparent data-[has-value=false]:text-transparent"
			data-has-value={value ? true : false}
			aria-hidden
		>
			{children}
		</div>
	)
}

SelectItem.displayName = 'SelectItem'

function CustomScrollButton({
	direction = 'down',
	viewportRef,
}: {
	direction: 'up' | 'down'
	viewportRef: React.RefObject<HTMLDivElement>
}) {
	const intervalRef = useRef<NodeJS.Timeout | null>(null)
	const scrollAmount = 10
	const delay = 50

	const startScrolling = () => {
		if (viewportRef.current) {
		  intervalRef.current = setInterval(() => {
			const el = viewportRef.current!
			const maxScrollTop = el.scrollHeight - el.clientHeight
	  
			if (direction === 'down') {
			  if (el.scrollTop >= maxScrollTop) {
				stopScrolling()
				return
			  }
			  el.scrollTop += scrollAmount
			} else {
			  if (el.scrollTop <= 0) {
				stopScrolling()
				return
			  }
			  el.scrollTop -= scrollAmount
			}
		  }, delay)
		}
	  }
	  

	  const stopScrolling = () => {
		if (intervalRef.current) {
		  clearInterval(intervalRef.current)
		  intervalRef.current = null
		}
	  }
	  

	return (
		<div
			onMouseEnter={startScrolling}
			onMouseLeave={stopScrolling}
			onMouseDown={startScrolling}
			onMouseUp={stopScrolling}
			onTouchStart={startScrolling}
			onTouchEnd={stopScrolling}
			className="flex align-middle justify-center content-center m-1 cursor-pointer"
		>
			<Icon
				name="chevronUp"
				size="size-5"
				color="text-grey-3"
				className={direction === 'down' ? 'rotate-180' : ''}
			/>
		</div>
	)
}

export function Select({
	required = false,
	autofocus = false,
	children = <SelectItem>Elemento default</SelectItem>,
	onValueChange = undefined,
	defaultValue = undefined,
	className,
	contentClassName,
	error,
	disabled = false,
	placeholder,
	label,
	...props
}: ISelect & SelectRadix.SelectProps): JSX.Element {
	const [value, setValue] = useState<string | null>(
		defaultValue ? defaultValue : null,
	)

	const viewportRef = useRef<HTMLDivElement>(null)
	const label_intern = label !== undefined ? label : placeholder

	return (
		<div className={className}>
			<SelectRadix.Root
				defaultValue={defaultValue}
				required={required}
				defaultOpen={autofocus}
				disabled={disabled}
				onValueChange={value => {
					setValue(value)
					if (onValueChange) {
						onValueChange(value)
					}
				}}
				{...props}
			>
				<div
					aria-disabled={disabled}
					className={cn(
						'inline-flex flex-col relative min-w-0 p-0 m-0 align-top w-full h-[50px] border border-solid border-grey-3 rounded z-0 aria-disabled:bg-grey-2 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
						typeof error === 'string' ? 'border-error focus-within:outline-error' : undefined,
					)}
				>
					{label_intern && <SelectLabel value={value}>{label_intern}</SelectLabel>}
					<SelectRadix.Trigger
						aria-disabled={disabled}
						className={cn(
							value ? '' : 'text-transparent',
							'text-grey-2 w-full group h-full bg-white rounded outline-none border-none text-left focus:border-gray-200 focus:outline-gray-200 flex justify-between items-center px-3 aria-disabled:cursor-not-allowed aria-disabled:opacity-50',
						)}
						aria-label={label_intern ?? undefined}
					>
						<SelectRadix.Value className="text-grey-4" placeholder={placeholder} />
						<SelectRadix.Icon className="absolute block right-[12px] top[calc(50%-6px)]" asChild>
							<Icon
								className="rotate-180 group-data-[state=open]:rotate-0 transition-all text-grey-3"
								name="chevronUp"
								size="size-5"
								color="inherit"
							/>
						</SelectRadix.Icon>
					</SelectRadix.Trigger>
					<SelectRadix.Portal>
						<SelectRadix.Content
							aria-describedby={error ? `error-${label_intern}` : undefined}
							className={cn(
								`relative overflow-hidden w-[calc(var(--radix-select-trigger-width)+2px)] max-h-[calc(3.15*var(--radix-select-trigger-height))] left[-10px] bg-white rounded shadow-md -left-px text-grey-2 z-[10]`,
								contentClassName,
							)}
							sideOffset={4}
							position={'popper'}
						>
							<CustomScrollButton direction="up" viewportRef={viewportRef} />
							<SelectRadix.Viewport className="p-3 text-grey-2" ref={viewportRef}>
								{children}
							</SelectRadix.Viewport>
							<CustomScrollButton direction="down" viewportRef={viewportRef} />
						</SelectRadix.Content>
					</SelectRadix.Portal>
				</div>
			</SelectRadix.Root>
			{typeof error === 'string' && (
				<Paragraph color="text-error" className="mt-2" id={`error-${label_intern}`}>
					{' '}
					{error}
				</Paragraph>
			)}
		</div>
	)
}
